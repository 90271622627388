import projectManagementIcon from "../Assets/Service/projectManagementIcon.svg"
import eCommerce from "../Assets/Service/eCommerce.svg"
import uiUxIcon from "../Assets/Service/uiUxIcon.svg"
import mobAppIcon from "../Assets/Service/mobAppIcon.svg"
import digitalMarketingIcon from "../Assets/Service/digitalMarketingIcon.svg"
import hireIcon from "../Assets/Service/hireIcon.svg"
import webDevIcon from "../Assets/Service/webDevIcon.svg"
import { FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

import bawsalaIcon from '../Assets/Work/bawsalaIcon.svg'
import bawsalaImage from '../Assets/Work/bawsalaImage.png'
import bawsala_headerBackground from '../Assets/Work/workDetails/bawsala/bawsala_headerBackground.png'
import bawsala_HeaderImage from '../Assets/Work/workDetails/bawsala/bawsala_HeaderImage.svg'
import bawsala_section2 from '../Assets/Work/workDetails/bawsala/bawsala_section2.png'
import bawsala_section3 from '../Assets/Work/workDetails/bawsala/bawsala_section3.png'
import bawsala_section4 from '../Assets/Work/workDetails/bawsala/bawsala_section4.png'
import bawsala_section5_background from '../Assets/Work/workDetails/bawsala/bawsala_section5_background.png'

import aseelIconWhite from '../Assets/Work/aseel/aseelIconWhite.svg'
import aseelImage from '../Assets/Work/aseelImage.png'
import aseel_section2 from '../Assets/Work/aseel/aseel_section2.png'
import aseel_section3 from '../Assets/Work/aseel/aseel_section3.png'
import aseel_section4Left from '../Assets/Work/aseel/aseel_section4Left.png'
import aseel_section4Right from '../Assets/Work/aseel/aseel_section4Right.png'
import aseel_section5_background from '../Assets/Work/aseel/aseel_section5_background.png'


import HKIcon from '../Assets/Work/hk/HKIcon.svg'
import HKImage from '../Assets/Work/hk/HKImage.png'
import hk_HeaderImage from '../Assets/Work/hk/hk_HeaderImage.png'
import hk_headerBackground from '../Assets/Work/hk/hk_headerBackground.png'
import hk_section2 from '../Assets/Work/hk/hk_section2.png'
import hk_section3 from '../Assets/Work/hk/hk_section3.png'
import hk_section4 from '../Assets/Work/hk/hk_section4.png'
import hk_section5_background from '../Assets/Work/hk/hk_section5_background.png'

import aljIconWhite from '../Assets/Work/alj/aljIconWhite.svg'
import ajlImage from '../Assets/Work/alj/ajlImage.png'
import ajl_section2 from '../Assets/Work/alj/ajl_section2.png'
import ajl_section4Left from '../Assets/Work/alj/ajl_section4Left.png'
import ajl_section4Right from '../Assets/Work/alj/ajl_section4Right.png'
import ajl_section5_background from '../Assets/Work/alj/ajl_section5_background.png'

import molemIcon from '../Assets/Work/molem/molemIcon.svg'
import molem_headerBackground from '../Assets/Work/molem/molem_headerBackground.svg'
import molemImage from '../Assets/Work/molem/molemImage.png'
import molem_section2 from '../Assets/Work/molem/molem_section2.png'
import molem_section3 from '../Assets/Work/molem/molem_section3.png'
import molem_section4Left from '../Assets/Work/molem/molem_section4Left.png'
import molem_section4Right from '../Assets/Work/molem/molem_section4Right.png'
import molem_section5_background from '../Assets/Work/molem/molem_section5_background.png'

import investorsMineIcon from '../Assets/Work/investorsMine/investorsMineIcon.svg'
import IM_HeaderImage from '../Assets/Work/investorsMine/IM_HeaderImage.png'
import im_section2 from '../Assets/Work/investorsMine/im_section2.png'
import im_headerBackground from '../Assets/Work/investorsMine/im_headerBackground.png'
import im_section3 from '../Assets/Work/investorsMine/im_section3.png'
import im_section4 from '../Assets/Work/investorsMine/im_section4.png'
import im_section5_background from '../Assets/Work/investorsMine/im_section5_background.png'


import anaostoriLogo from '../Assets/Work/anaostori/AnaAstoriLogo.svg'
import anaostori_header_image from '../Assets/Work/anaostori/anaostori_header_image.png'
import anaostori_section2 from '../Assets/Work/anaostori/anaostori_section2.png'
import anaostori_section3 from '../Assets/Work/anaostori/anaostori_section3.png'
import anaostori_section4 from '../Assets/Work/anaostori/anaostori_section4.png'
import anaostori_section5_background from '../Assets/Work/anaostori/anaostori_section5_background.png'

import pilatesLogo from '../Assets/Work/pilatesRoom/pilatesLogo.svg'
import pilatesRoom_headerImage from '../Assets/Work/pilatesRoom/pilatesRoom_headerImage.png'
import pilatesSection2 from '../Assets/Work/pilatesRoom/pilatesSection2.png'
import pilatesSection3 from '../Assets/Work/pilatesRoom/pilatesSection3.png'
import pilatesSection4 from '../Assets/Work/pilatesRoom/pilatesSection4.png'
import pilates_section5_background from '../Assets/Work/pilatesRoom/pilates_section5_background.png'




export const serviceArray = [
    {
        icon: uiUxIcon,
        path: '/services',
        title: 'UX/UI Design',
        text: 'Our approach to UI/UX is psychological. We transform your thoughts into action by taking the user-centric approach and making your ideas a reality!',
        tags: [
            { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
            { title: 'Project Management', color: 'rgba(255, 104, 70, 1)' },
        ]
    },
    {
        icon: webDevIcon,
        path: '/services',
        title: 'Web Development',
        text: 'Websites that are unique and tailored are a key requisite in today’s technologically driven market and that is what our developers will give for you!!',
        tags: [
            { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
            { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
            { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
        ]
    },
    {
        icon: mobAppIcon,
        path: '/services',
        title: 'Mobile App',
        text: 'Our customized mobile apps ensure ease and adaptability to users. We use the latest technology to develop IOS/Andriod apps that are sure to drive your business towards success!!',
        tags: [
            { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
            { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
            { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
        ]
    },
    {
        icon: eCommerce,
        path: '/services',
        title: 'E-Commerce for Web and Mobile',
        text: 'We create robust e-commerce platforms using Shopify, WooCommerce, and WordPress to develop dynamic websites and mobile apps. Our solutions deliver seamless user experiences and scalable designs, helping brands grow their digital presence effectively.',
        tags: [
            { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
            { title: 'CRM', color: 'rgba(125, 76, 176, 1)' },
            { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
        ]
    },
    {
        icon: projectManagementIcon,
        path: '/services',
        title: 'AR/VR Development',
        text: 'We help businesses come up with Augmented Reality use-cases that can enhance customer engagement and help them create a unique brand identity. We also, create an interactive and immersive Virtual Reality experience, in order to help your customers understand your products and offerings efficiently.',
        tags: [
            { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
            { title: 'CRM', color: 'rgba(125, 76, 176, 1)' },
            { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
        ]
    },
    {
        icon: hireIcon,
        title: 'Hire a Developer',
        path: '/contact',
        text: 'Developing apps and programs is an art for us! If you are looking for a developer that really gets what accuracy and attention to small detail means to a project, you are in the right place!! We have a totally dedicated and driven team of developers who are extremely creative and highly organized with excellent problem-solving ability.  They collaborate with various stakeholders to understand their needs and deliver exactly what is expected within the given timelines!',
        tags: [
            { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
            { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
            { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
        ]
    },
    {
        icon: digitalMarketingIcon,
        path: '/contact',
        title: 'Digital Marketing',
        text: 'Brand presence and targeted media presence is essential to position products and services and to this end, we will popularize your business with the perfect outreach!',
        tags: [
            { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
            { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
            { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
        ]
    },


]

export const socialLinks = [
    {
        link: FaInstagram,
        // url: "https://devopscommunity.org",
    },
    {
        link: FaTwitter,
        // url: "https://twitter.com/maktechio",
    },
    {
        link: FaLinkedinIn,
        url: "https://www.linkedin.com/company/14566567",
    },
]

export const workArray = [
    {
        icon: bawsalaIcon,
        workImg: bawsalaImage,
        title: 'Bawsala',
        type: 'mobile',
        description: 'BAWSALA is an addressing system that redefines addresses from the ordinary, old, language-specific, complex and multi-line addresses into a simple, single line, elegant, digitised, and searchable Alphanumeric code',
        image: bawsala_HeaderImage,
        backgroundImg: bawsala_headerBackground,
        backgroundSize: 'contain',
        backgroundPosition: 'bottom center',
        otherprojectImg: bawsalaImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI ,Product Design, Branding, Development, Testing, Deployment',
            company: 'Bawsala'
        },
        sections: [
            {
                title: 'Search Place',
                description: 'We have assigned unique codes to each and every house, apartment, villa and buildings. Search places using Bawsala Code (like JDRW 1558) or Address.',
                img: bawsala_section2
            },
            {
                title: 'Select Building',
                description: 'Tap on top of any building to get its Bawsala Code. Borders will be drawn and its enterences will be placed.',
                img: bawsala_section3
            },
            {
                title: 'Navigate to the address',
                description: 'To navigate to any place, navigate using Google Maps, Apple Maps or Waze.',
                img: bawsala_section4
            }
        ],
        imgSection: { img: bawsala_section5_background }
    },
    {
        icon: HKIcon,
        workImg: HKImage,
        title: 'Home Kitchen',
        type: 'mobile',
        description: 'Living in a different city? Missing home cook food? Homekitch brings food right from the kitchen of home Chef.',
        image: hk_HeaderImage,
        backgroundImg: hk_headerBackground,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        otherprojectImg: HKImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI ,Product Design, Branding, Development, Testing, Deployment',
            company: 'Home Kitchen'
        },
        sections: [
            {
                title: 'Explore Kitchen',
                description: 'Checkout kitchen details like cuisine, ratings, photos, reviews and chef details.',
                img: hk_section2
            },
            {
                title: 'Order Details',
                description: 'Preview you order details by confirming your items and address.',
                img: hk_section3
            },
            {
                title: 'Track your order',
                description: 'Get an live update of your order with details like time, order status and driver details.',
                img: hk_section4
            }
        ],
        imgSection: { img: hk_section5_background }
    },
    {
        icon: anaostoriLogo,
        workImg: anaostori_header_image,
        title: 'Anaostori',
        type: 'web',
        description: "Anaostori provides courses for Saudi Arabia's Qudrat and Tahsili exams, along with an easy-to-use booking management system for reserving slots.",
        image: anaostori_header_image,
        // backgroundImg: anaostoriHeaderImage,
        // backgroundSize: 'contain',
        // backgroundPosition: 'bottom center',
        otherprojectImg: anaostori_header_image,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment and Maintenance',
            company: 'Anaostori'
        },
        section2: {
            img: anaostori_section2
        },
        section3: {
            // title: 'Lorem ipsum',
            description: "Anaostori offers specialized courses to help students excel in Saudi Arabia's Qudrat (Aptitude) and Tahsili(Achievement) exams. Backed by a team of expert trainers and supervisors, the platform provides high-quality, engaging lessons tailored for both male and female students. Courses are designed with an appealing and youthful approach, ensuring that students remain motivated and well-prepared.",
            img: anaostori_section3
        },
        section4: {
            title:'Booking Management System',
            description:"Anaostori offers an integrated Booking Management System for reserving slots. This system allows students to secure seats with ease, featuring real-time availability and automated confirmations. With the support of licensed instructors and a youthful, motivating design, Anaostori helps students succeed while making registration effortless.",
            imgLeft: anaostori_section4,
        },
        imgSection: { img: anaostori_section5_background }
    },
    {
        icon: pilatesLogo,
        workImg: pilatesSection2,
        title: 'The Pilates Room',
        type: 'mobile',
        description: 'Effortlessly book various pilates classes, each with flexible packages tailored to different needs and fitness levels.',
        image: pilatesRoom_headerImage,
        imageStyles:{maxHeight:'100vh', width:'auto'},
        // backgroundImg: bawsala_headerBackground,
        // backgroundSize: 'contain',
        // backgroundPosition: 'bottom center',
        otherprojectImg: pilatesSection2,
        section1: {
            useWbheader:true,
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment and Maintenance',
            company: 'The Pilates Room'
        },
        sections: [
            {
                title: 'Classes',
                description: 'Stay organized with your pilates schedule—view upcoming classes, and easily cancel or reschedule when needed for flexibility.',
                img: pilatesSection2
            },
            {
                title: 'Packages',
                description: 'Packages cater to all levels and include options for different class types, like reformer and mat pilates. Packages are flexible, offering choices between private sessions, small groups, and class bundles to match your fitness goals.',
                img: pilatesSection3
            },
            {
                title: 'Account Management',
                description: 'Easily manage your account with customizable settings, allowing you to update personal information, adjust communication preferences, and track your pilates progress. Control your booking options and view class history to enhance your experience.',
                img: pilatesSection4
            }
        ],
        imgSection: { img: pilates_section5_background }
    },
    {
        icon: aseelIconWhite,
        workImg: aseelImage,
        title: 'Aseel',
        type: 'web',
        description: 'Aseel is a crowdsource funding platform in Real estate. Made it easier for people to invest in real estate.',
        image: aseelImage,
        // backgroundImg: bawsala_headerBackground,
        otherprojectImg: bawsala_HeaderImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'Aseel'
        },
        section2: {
            img: aseel_section2
        },
        section3: {
            title: 'Investment Opportunity',
            description: 'Find details of the investments like- Duration of investment, total value of the project, Interest rate, location and many other things that will help user to understand and invest in the opportunity.',
            img: aseel_section3
        },
        section4: {
            // title:'Lorem ipsum',
            // description:'LoLorem ipsum dolor sit amet, consectetur adipiscing elit. Potenti maecenas commodo habitant suspendisse pretium. Nec sem turpis purus mauris. Elementum ultrices eu arcu pretium lectus ante faucibus accumsan. Sagittis, tellus justo, sit mi a ut diam donec. Arcu.',
            imgLeft: aseel_section4Left,
            imgRight: aseel_section4Right
        },
        imgSection: { img: aseel_section5_background }
    },
    {
        icon: aljIconWhite,
        workImg: ajlImage,
        title: 'ALJ',
        type: 'web',
        description: 'ALJOC is one of the leading Motor Oil companies in Saudi Arabia.',
        image: ajlImage,
        // backgroundImg: bawsala_headerBackground,
        otherprojectImg: ajlImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'Abdul Latif Jameel'
        },
        section2: {
            img: ajl_section2
        },
        section3: {
            title: 'Product Listing',
            description: 'View range of line of products provided by ALJOC based on the vehicle, make, engine type, etc.',
            img: ajl_section2
        },
        section4: {
            // title:'Lorem ipsum',
            // description:'LoLorem ipsum dolor sit amet, consectetur adipiscing elit. Potenti maecenas commodo habitant suspendisse pretium. Nec sem turpis purus mauris. Elementum ultrices eu arcu pretium lectus ante faucibus accumsan. Sagittis, tellus justo, sit mi a ut diam donec. Arcu.',
            imgLeft: ajl_section4Left,
            imgRight: ajl_section4Right
        },
        imgSection: { img: ajl_section5_background }
    },
    {
        icon: molemIcon,
        workImg: molemImage,
        title: 'Mulim',
        type: 'web',
        description: 'The investment awareness program ‘Mulim’ was launched by the securities authorities in the Gulf Cooperation Council countries, which aims to raise awareness of the culture of financial transactions and investment in the financial markets, through a number of awareness campaigns and events offered by the program.',
        image: molemImage,
        backgroundImg: molem_headerBackground,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        otherprojectImg: molemImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'CMA'
        },
        section2: {
            img: molem_section2
        },
        section3: {
            title: 'Smart Investor Award',
            description: 'Showing all the tracks for the compition and the awards.',
            img: molem_section3
        },
        section4: {
            // title:'Lorem ipsum',
            // description:'LoLorem ipsum dolor sit amet, consectetur adipiscing elit. Potenti maecenas commodo habitant suspendisse pretium. Nec sem turpis purus mauris. Elementum ultrices eu arcu pretium lectus ante faucibus accumsan. Sagittis, tellus justo, sit mi a ut diam donec. Arcu.',
            imgLeft: molem_section4Left,
            imgRight: molem_section4Right
        },
        imgSection: { img: molem_section5_background }
    },
    {
        icon: investorsMineIcon,
        workImg: IM_HeaderImage,
        title: 'Investors Mine',
        type: 'mobile',
        description: 'Investors Mine is a platform for Angel investors and VCs to find and browse investment opportunities. Also, book meetings with the founders to learn more about their products and ideas.',
        image: IM_HeaderImage,
        backgroundImg: im_headerBackground,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        otherprojectImg: IM_HeaderImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'Investors Mine'
        },
        sections: [
            {
                title: 'Investment Opportunities',
                description: 'View list of different opportunities from different domains which are looking for investments.',
                img: im_section3
            },
            {
                title: 'Opportunity Details',
                description: 'Know more about the opportunity by watching product video or reading relevant documents.',
                img: im_section4
            },
            {
                title: 'User Profile',
                description: 'View your investments and customize your settings as per your needs.',
                img: im_section2
            }
        ],
        imgSection: { img: im_section5_background }
    },
]